import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB } from 'date-fns/locale'; // Importa el idioma español desde date-fns
import { es } from 'date-fns/locale'; // Importa el idioma español desde date-fns
import { ca } from 'date-fns/locale'; // Importa el idioma español desde date-fns

import './Calendar.css';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { getLanguage } from '../language/i18n.js';

import { getMySQLDateFromJSDate } from '../utils/DateUtils';

function Calendar({ date, setDate }) {

  const { t } = useTranslation();

  const handleRemoveDay = () => {
    setDate(new Date(date.getTime() - 86400000));
  };

  const handleAddDay = () => {
    setDate(new Date(date.getTime() + 86400000));
  };

  /* const handleSetDate = (e) => {
    setDate(new Date(e.target.value));
  } */

  const handleSetDate= (e) => {
    setDate(e);
  }

  const getCalendarLanguage = () => {
    let lang = getLanguage();
    if(lang === '"ca"') return ca;
    if(lang === '"es"') return es;
    if(lang === '"en"') return enGB;
  }

  return (
    <div className='divCalendar'>
      <button onClick={handleRemoveDay}>&lt;</button>
      {/*
      <input type="date" value={getMySQLDateFromJSDate(date)} onChange={(e) => handleSetDate(e)} />
      */}
      <DatePicker
        selected={getMySQLDateFromJSDate(date)}
        onChange={(date) => handleSetDate(date)}
        dateFormat="dd/MM/yyyy"
        closeOnScroll={true}
        locale={getCalendarLanguage()}
        todayButton={t('today')}
        calendarStartDay={1}
      />
      <button onClick={handleAddDay}>&gt;</button>
    </div>
  );
};

export default Calendar;
