import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/english.json';
import es from './translations/spanish.json';
import ca from './translations/catalan.json';
import gl from './translations/galego.json';
import eu from './translations/euskera.json';

export const getLanguage = () => {
    try {
        const cachedLang = localStorage.getItem('lang');
        if (cachedLang !== null) {
            return cachedLang;
        }
    } catch (error) {
    }
};

export const getLanguages = () => {
    return [
        { label: 'Español', value: 'es' },
        { label: 'Català', value: 'ca' },
        { label: 'Galego', value: 'gl' },
        { label: 'Euskera', value: 'eu' },
        { label: 'English', value: 'en' }
    ];
}

export const getLanguageNameFromLang = (theLang) => {
    let theOpt = getLanguages().filter((op) => op.value === theLang);
    if (theOpt && theOpt.length > 0) {
        return theOpt[0].label;
    }

    return '';
}

export const saveLanguage = (lang) => {
    try {
        localStorage.setItem('lang', JSON.stringify(lang));
    } catch (error) {
        // Catch error
    }
};

i18n
    .use(initReactI18next) // Allow to use i18n in react
    .init({
        fallbackLng: 'es', // Default language
        resources: {
            en: {
                translation: en,
            },
            es: {
                translation: es,
            },
            ca: {
                translation: ca,
            },
            gl: {
                translation: gl
            },
            eu: {
                translation: eu
            }
        },
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;