import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n.js';

import './AdminClasses.css';

function AdminClasses({ usersList, classesList, DefaultColumnFilter, caseInsensitiveSort }) {

  const { t } = useTranslation();

  const getUserNameById = (userId) => {
    let theUser = usersList.filter((u) => u._id === userId);
    return theUser.length > 0 ? (theUser[0].name + ' ' + theUser[0].surname) : '';
  }

  const [showId, setShowId] = useState(false);
  const [showName, setShowName] = useState(true);
  const [showSchoolName, setShowSchoolName] = useState(true);
  const [showUser, setShowUser] = useState(true);
  const [showStudents, setShowStudents] = useState(true);

  const columns = useMemo(() => {
    let allColumns = [
      { Header: t('class_id'), accessor: '_id', Filter: DefaultColumnFilter, show: showId },
      { Header: t('class_name'), accessor: 'name', Filter: DefaultColumnFilter, sortType: caseInsensitiveSort, show: showName },
      { Header: t('class_school_name'), accessor: 'school_name', Filter: DefaultColumnFilter, sortType: caseInsensitiveSort, show: showSchoolName },
      {
        Header: t('class_user'),
        accessor: (row) => {
          const userName = row.user_id ? getUserNameById(row.user_id) : '';
          return userName;
        }, Filter: DefaultColumnFilter, sortType: caseInsensitiveSort, show: showUser
      },
      {
        Header: t('class_students'), 
        accessor: (row) => {
          const studentsCount = row.students && row.students.length > 0 ? row.students.length : 0;
          return studentsCount;
        }, Filter: DefaultColumnFilter, show: showStudents
      },
    ];

    return allColumns.filter(column => column.show);

    // eslint-disable-next-line
  }, [showId, showName, showSchoolName, showUser, showStudents, t]);

  const data = useMemo(() => classesList, [classesList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    // eslint-disable-next-line
  } = useTable({ columns, data }, useFilters, useSortBy);

  const handleCheckboxChange = (setter) => (event) => {
    setter(event.target.checked);
  };

  return (
    <div className='divAdminClasses'>
      <>
        <div>
          {/* Checkboxes para mostrar/ocultar columnas */}
          <label>
            <input type="checkbox" checked={showId} onChange={handleCheckboxChange(setShowId)} />
            {t('class_id')}
          </label>
          <label>
            <input type="checkbox" checked={showName} onChange={handleCheckboxChange(setShowName)} />
            {t('class_name')}
          </label>
          <label>
            <input type="checkbox" checked={showSchoolName} onChange={handleCheckboxChange(setShowSchoolName)} />
            {t('class_school_name')}
          </label>
          <label>
            <input type="checkbox" checked={showUser} onChange={handleCheckboxChange(setShowUser)} />
            {t('class_user')}
          </label>
          <label>
            <input type="checkbox" checked={showStudents} onChange={handleCheckboxChange(setShowStudents)} />
            {t('class_students')}
          </label>
        </div>
        <table {...getTableProps()} style={{ width: '100%', border: '1px solid black' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {/* Heading to click to sort */}
                    <div {...column.getSortByToggleProps()}>
                      {column.render('Header')}
                      {/* Sort indicator */}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </div>

                    {/* Filter */}
                    <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>{t('table_total_rows') + ' ' + rows.length}</div>
      </>
    </div>

  );
};

export default AdminClasses;
