import React, { useEffect, useState } from 'react';
import './ClassList.css';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import Cookies from '../../components/Cookies';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ClassList({ user, classesData, setClassesData, schoolsData, message,
  setMessage, loading, setLoading, selectedClass, setSelectedClass, logoutMain }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showCookies, setShowCookies] = useState(true);

  const acceptCookies = () => {
    localStorage.setItem('cookies', true);
    setShowCookies(false);
  }

  useEffect(() => {
    try {
      const cachedCookies = localStorage.getItem('cookies');

      if (cachedCookies !== null) {
        if (cachedCookies === 'true') {
          setShowCookies(false);
        } else {
          setShowCookies(true);
        }
      } else {
        setShowCookies(true);
      }
    } catch (error) {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToNewClass = () => {
    navigate('/newclass');
  }

  useEffect(() => {
    setSelectedClass(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClassClick = (theClass) => {
    setSelectedClass(theClass);
    navigate('/classes/' + theClass._id);
  }

  return (
    <div>

      <div className='divClassListFirstRow'>
        <h1>{t('classlist')}</h1>

      </div>

      {showCookies && <Cookies setShowCookies={setShowCookies} acceptCookies={acceptCookies} logoutMain={logoutMain} />}


      {(!classesData || classesData.length === 0)
        &&
        <h2>{t('no-classes')}</h2>}
      <div className="divClassList">
        {classesData && classesData.sort((a, b) => a.name.localeCompare(b.name)).map((theClass) => (
          <div id={'class' + theClass._id} className="divClassItem" key={theClass._id}
            onClick={() => onClassClick(theClass)}>
            <h2 className="className">{theClass.name}</h2>
            <p className="schoolName">{theClass.school_name}</p>
            {theClass.user_id !== user._id &&
              <p className='invited'>{t('invited')}</p>
            }
          </div>
        ))}
      </div>
      <button className='btnNewClassText' onClick={() => goToNewClass()}>
        {/* {t('new-class-btn')} */}
        <FontAwesomeIcon className='iconFruitity' icon={faAdd}/>
      </button>
    </div>
  );
};

export default ClassList;
