import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './NewClass.css';
import NewStudents from './NewStudents';
import { useNavigate } from 'react-router-dom';
import { addClass } from '../../utils/APIUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

function NewClass({ user, message, setMessage, schools, setSchools, loading, setLoading, classesData, setClassesData, logoutMain }) {
  const { t } = useTranslation();


  const [className, setClassName] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [studentList, setStudentList] = useState([
    { name: '' }
  ]);
  const navigate = useNavigate();

  const createClass = async () => {
    let theList = [];
    theList = studentList.filter((s) => s.name !== '');

    setLoading(true);

    const theData = await addClass(user.token, schoolName, className, theList);

    if (theData && theData.ok) {
      let updatedClasses = [...classesData];
      updatedClasses.push(theData.data);
      setClassesData(updatedClasses);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
    setStudentList([{ name: '' }]);
    navigate('/classes');
  }

  return (
    <div className='divNewClass'>
      <h1>{t('new-class-title')}</h1>
      <div>
        <label>{t('schoolname') + ':'}</label>
        <input type="text" id="schoolname" name="schoolname" value={schoolName} placeholder={t('schoolname')} onChange={(e) => setSchoolName(e.target.value)} />
      </div>

      <div>
        <label>{t('classname') + ':'}</label>
        <input type="text" id="classname" name="classname" value={className} placeholder={t('classname')} onChange={(e) => setClassName(e.target.value)} />
      </div>

      <div className='divNewClassStudents'>
        <NewStudents
          studentList={studentList}
          setStudentList={setStudentList}
        />
      </div>

      <button
        onClick={createClass}
        disabled={studentList.length < 2 || className === '' || schoolName === ''}
        className={`btnCreateClass 
            ${(studentList === null || studentList.length < 2 || className === '' || schoolName === '') && 'buttonDisabled'}
            `}
      ><FontAwesomeIcon className='iconFruitity' icon={faSave} /></button>
      
    </div>
  );
};

export default NewClass;
