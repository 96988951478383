import React, { useEffect, useRef, useState } from 'react';
import './Layout.css';
import { Outlet } from 'react-router-dom';
import Message from './Message';
import Loading from './Loading';
import Menu from './Menu';
import { useTranslation } from 'react-i18next';
import { sendActivationEmail } from '../utils/APIUtils';

const Layout = ({ user, loading, setLoading, message, setMessage, menu, classesData, selectedClass, setSelectedClass, logoutMain }) => {
  const { t } = useTranslation();

  //Get div to recalculate height to do not go beyond the screen
  const divVScrollRef = useRef(null);
  // Use state to save the size of the student list div
  const [divVScrollHeight, setDivVScrollHeight] = useState(100);

  const sendEnableUserEmail = async () => {
    setLoading(true);
    const theData = await sendActivationEmail(user.token);

    if (theData && theData.ok) {
      setMessage({ msg: t('email-activation-sended'), type: 'OK' });
    } else {
      setMessage({ msg: t(theData.error), type: 'ERR' });
    }

    setLoading(false);
  }

  useEffect(() => {

    const recalculateHeightVScroll = () => {
      if (divVScrollRef.current) {
        const windowHeight = window.innerHeight;
        const elementTop = divVScrollRef.current.getBoundingClientRect().top;
        const calculatedHeight = windowHeight - elementTop;
        setDivVScrollHeight(calculatedHeight);
      }
    };

    // Recalculate div height at the beginning
    recalculateHeightVScroll();
    // When window size change recalculate
    window.addEventListener('resize', recalculateHeightVScroll);
    // Remove listener when unmount component
    return () => {
      window.removeEventListener('resize', recalculateHeightVScroll);
    };
  })


  return (
    <div>
      {loading && <Loading />}
      {menu && <Menu user={user} classesData={classesData} selectedClass={selectedClass} setSelectedClass={setSelectedClass} />}
      {/* MESSAGE */}
      {message && <Message message={message} setMessage={setMessage} />}
      {user && !user.enabled &&
        <>
          <h3 className='warningUserNotEnabledYet'>{t('user-not-enabled-yet')}</h3>
          <button className='btnSendActivationEmailAgain' onClick={() => sendEnableUserEmail()}>
            {t('send-activation-email')}
          </button>
        </>
      }
      <div ref={divVScrollRef} className='divVScroll'
        style={{ height: `${divVScrollHeight}px`, }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;