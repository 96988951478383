import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import Podium from '../../../components/Podium.js';
import '../../../language/i18n.js';
import { getFruitName } from '../../../utils/FruitImgUtil.js';

import './AdminSummary.css';

function AdminSummary({ user, message, setMessage, loading, setLoading, logoutMain, usersList, classesList }) {

  const { t } = useTranslation();

  const [totalUsers, setTotalUsers] = useState(0);
  const [usersLast15days, setUsersLast15days] = useState(0);
  const [usersByLanguage, setUsersByLanguage] = useState({});

  const [totalClasses, setTotalClasses] = useState(0);
  const [classesWithMoreThan10Students, setClassesWithMoreThan10Students] = useState(0);

  const [meals, setMeals] = useState([]);
  const [mealsByClass, setMealsByClass] = useState([]);
  const [mealsByStudent, setMealsByStudent] = useState([]);

  const [maxItemsFruits, setMaxItemsFruits] = useState(10);
  const [maxItemsClasses, setMaxItemsClasses] = useState(10);
  const [maxItemsStudents, setMaxItemsStudents] = useState(10);


  useEffect(() => {
    if (classesList) {
      setTotalClasses(classesList.length);

      const classesWithManyStudents = classesList.filter(
        (cls) => cls.students.length > 10
      );
      setClassesWithMoreThan10Students(classesWithManyStudents.length);

      const fruitTotals = {};
      const mealsByClass = {};
      const mealsByStudent = {};

      classesList.forEach((cls) => {

        const className = `${cls.name} - ${cls.school_name}`;
        if (!mealsByClass[className]) {
          mealsByClass[className] = 0;
        }

        cls.students.forEach((student) => {
          const studentName = `${className} - ${student.name}`;
          mealsByStudent[studentName] = student.meals.length;

          student.meals.forEach((meal) => {
            mealsByClass[className] += 1;
            const fruitName = t(getFruitName(meal.fruit));

            if (fruitTotals[fruitName]) {
              fruitTotals[fruitName] += 1;
            } else {
              fruitTotals[fruitName] = 1;
            }
          });
        });
      });

      //Convert and save
      let fruitList = Object.entries(fruitTotals).map(([name, total]) => ({
        name,
        total,
      }));
      fruitList = fruitList.sort((f1, f2) => f2.total - f1.total);
      setMeals(fruitList);

      //Convert and save
      let mealsListByClass = Object.entries(mealsByClass).map(
        ([name, total]) => ({
          name,
          total,
        })
      );
      mealsListByClass = mealsListByClass.sort((f1, f2) => f2.total - f1.total);
      setMealsByClass(mealsListByClass);

      //Convert and save
      let mealsListByStudent = Object.entries(mealsByStudent).map(
        ([name, total]) => ({
          name,
          total,
        })
      );
      mealsListByStudent = mealsListByStudent.sort((f1, f2) => f2.total - f1.total);
      setMealsByStudent(mealsListByStudent);

    }
  }, [classesList, t]);


  useEffect(() => {
    if (usersList) {
      setTotalUsers(usersList.length);

      const today = new Date();
      const fifteenDaysAgo = new Date();
      fifteenDaysAgo.setDate(today.getDate() - 15);

      const recentUsers = usersList.filter(user => {
        return user.accesses.some(access => new Date(access) > fifteenDaysAgo);
      });
      setUsersLast15days(recentUsers.length);

      const languageCounts = usersList.reduce((acc, user) => {
        acc[user.language] = (acc[user.language] || 0) + 1;
        return acc;
      }, {});
      setUsersByLanguage(languageCounts);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  const handleFruitsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setMaxItemsFruits(value);
    }
  };

  const handleClassesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setMaxItemsClasses(value);
    }
  };

  const handleStudentsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setMaxItemsStudents(value);
    }
  };

  return (
    <div className='divAdminSummary'>
      <h1>{t('users')}</h1>
      <div className='divAdminSummaryUsers'>
        <div>
          <h2>{t('sum_total_users')}</h2>
          <p>{totalUsers}</p>
        </div>
        <div>
          <h2>{t('sum_total_users_access_last_15days')}</h2>
          <p>{usersLast15days}</p>
        </div>
        <div>
          <h2>{t('sum_total_users_per_language')}</h2>
          <ul>
            {Object.entries(usersByLanguage).map(([language, count]) => (
              <li key={language}>
                {language}: {count}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <h1>{t('classes')}</h1>
      <div className='divAdminSummaryClasses'>
        <div>
          <h2>{t('sum_total_classes')}</h2>
          <p>{totalClasses}</p>
        </div>
        <div>
          <h2>{t('sum_total_classes_more10students')}</h2>
          <p>{classesWithMoreThan10Students}</p>
        </div>

      </div>

      <h1>{t('totals')}</h1>
      <div className='divAdminSummaryFruits'>
        <div>
        <input 
          type="number" 
          value={maxItemsFruits} 
          onChange={handleFruitsChange} 
          min="3" 
        />
          <Podium title={t('sum_total_fruits_total_graph')} theList={meals.slice(0, maxItemsFruits)} />
        </div>
        <div>
        <input 
          type="number" 
          value={maxItemsClasses} 
          onChange={handleClassesChange} 
          min="3" 
        />
          <Podium title={t('sum_total_classes_fruits_graph')} theList={mealsByClass.slice(0, maxItemsClasses)} />

        </div>
        <div>
        <input 
          type="number" 
          value={maxItemsStudents} 
          onChange={handleStudentsChange} 
          min="3" 
        />
          <Podium title={t('sum_total_classes_students_graph')} theList={mealsByStudent.slice(0, maxItemsStudents)} />
        </div>
      </div>
    </div>

  );
};

export default AdminSummary;
