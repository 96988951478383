import React, { useState } from 'react';
import './Menu.css';

import fruitityIcon from '../assets/fruitity-icon.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faBars, faChalkboardTeacher, faGamepad, faLock, faNewspaper, faQuestion, faSchool } from '@fortawesome/free-solid-svg-icons';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import '../language/i18n.js';

import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Menu = ({ user, classesData, selectedClass, setSelectedClass }) => {

    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleMenuClick = () => {
        if (menuOpen) {

            const menuOptions = document.querySelector('.divMenuNavOptions');
            if (menuOptions) {
                menuOptions.classList.add('divMenuNavOptionsHide');
                setTimeout(() => {
                    menuOptions.classList.remove('divMenuNavOptionsHide');
                    setMenuOpen(!menuOpen);
                }, 250);
            } else {
                setMenuOpen(!menuOpen);
            }
        } else {

            setMenuOpen(!menuOpen);
        }
    };

    const onClickMenuItem = (navTo) => {
        navigate(navTo);
        handleMenuClick();
    }

    const onClickMenuClass = (theClass) => {
        navigate('/classes/' + theClass._id);
        setSelectedClass(theClass);
        handleMenuClick();
    }

    return (
        <div className='divMenu'>
            <nav className='menuNav'>
                <div className='menuNavTitle'>
                    <div className='menuNavTitleLeft'>
                        {user && user.news && user.news.filter(n => !n.readed).length > 0 && <p className='menuItemNumberNews menuItemNumberNewsBar'>{user.news.filter(n => !n.readed).length}</p>}
                        <FontAwesomeIcon className="menuIcon" icon={faBars} onClick={handleMenuClick} />
                        <img alt="fruitityicon" className="menuLogo" src={fruitityIcon} />
                        <h1 className='menuTitle'>Fruitity</h1>
                    </div>
                    {
                        selectedClass && pathname.startsWith('/classes') &&
                        <h3 className="menuClassName">{selectedClass && selectedClass.name}</h3>
                    }
                </div>
            </nav>

            {menuOpen &&
                <div className='divMenuNavOptions'>
                    <div className="menuIconLink" onClick={() => onClickMenuItem('/classes')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faSchool} />
                        <p className='menuIconText'>{t('classes')}</p>
                    </div>
                    <div className='divMenuClasses'>
                        {classesData && classesData.sort((a, b) => a.name.localeCompare(b.name)).map((cl) => (
                            <div key={cl._id}
                                onClick={() => onClickMenuClass(cl)}
                                className="menuIconLink menuIconLinkClass"
                            >
                                <FontAwesomeIcon className="menuIconItem" icon={faChalkboardTeacher} />
                                <p className='menuIconText'>{cl.name + (cl.user_id !== user._id ? ' (' + t('invited') + ')' : '')}</p>
                            </div>
                        ))}
                    </div>
                    <div className="menuIconLink" onClick={() => onClickMenuItem('/awards')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faAward} />
                        <p className='menuIconText'>{t('awards')}</p>
                    </div>

                    <div className="menuIconLink" onClick={() => onClickMenuItem('/games')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faGamepad} />
                        <p className='menuIconText'>{t('games')}</p>
                    </div>
                    <div className="menuIconLink menuItemNews" onClick={() => onClickMenuItem('/news')}>
                        {user && user.news && user.news.filter(n => !n.readed).length > 0 && <p className='menuItemNumberNews'>{user.news.filter(n => !n.readed).length}</p>}
                        <FontAwesomeIcon className="menuIconItem" icon={faNewspaper} />
                        <p className='menuIconText'>{t('news')}</p>
                    </div>
                    <div className="menuIconLink" onClick={() => onClickMenuItem('/user')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faUserAlt} />
                        <p className='menuIconText'>{t('user')}</p>
                    </div>
                    <Link to="https://fruitity.com/tutorials.html" target="_blank" className="menuLinkTutorial">
                        <div className="menuIconLink">
                            <FontAwesomeIcon className="menuIconItem" icon={faQuestion} />
                            <p className='menuIconText'>{t('tutorial')}</p>
                        </div>
                    </Link>
                    <div className='menuIconLink' onClick={handleLogout}>
                        <FontAwesomeIcon className="menuIconItem" icon={faSignOutAlt} />
                        <p className='menuIconText'>{t('logout')}</p>
                    </div>
                    {
                    user.isAdmin && 
                        <div className='menuIconLink' onClick={() => onClickMenuItem('/admin')}>
                            <FontAwesomeIcon className="menuIconItem" icon={faLock} />
                            <p className='menuIconText'>{t('admin')}</p>
                        </div>
                    }
                </div>
            }

        </div>
    );
};

export default Menu;