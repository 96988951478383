import React, { useEffect, useRef } from 'react';
//import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
//import { getAllFruitImage } from '../../utils/FruitImgUtil.js';
import './CatchFruits.css';
import cart from '../../assets/games/catchfruits/cart.png';

function CatchFruits({ user, message, setMessage, loading, setLoading, logoutMain }) {
  //const { t } = useTranslation();

  const canvasRef = useRef(null);

  //const fruits = getAllFruitImage();

  const calculateHeight = (width) => {
    return window.innerHeight * width / window.innerWidth;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = calculateHeight(canvas.width);
    const ctx = canvas.getContext('2d');

    //ctx.fillStyle = 'green';
    //ctx.fillRect(0, 0, canvas.width, canvas.height);

    const cartImg = new Image();
    cartImg.src = cart;

    let cartX = (canvas.width - 35) / 2;
    const cartY = canvas.height - 70;
    const cartWidth = 70;
    const cartHeight = calculateHeight(70);

    function draw() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(cartImg, cartX, cartY, cartWidth, cartHeight);
    }

    cartImg.onload = () => {
      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(cartImg, cartX, cartY, cartWidth, cartHeight);
    }


    canvas.addEventListener('mousemove', function (event) {
      cartX = event.clientX - 35;
      draw();
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className='catchfruits'>
      <div>

      </div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default CatchFruits;
