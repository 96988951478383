import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import '../../language/i18n.js';
import CatchFruits from './CatchFruits.js';
import Fruitzzle from './Fruitzzle.js';
import './Games.css';
import MemoryFruits from './MemoryFruits.js';
import memoryfruitsImg from '../../assets/games/memoryfruits.png';
import fruitzzleImg from '../../assets/games/fruitzzle.png';

function Games({ user, message, setMessage, loading, setLoading, logoutMain }) {

  const memoryFruits = 'memoryfruits';
  const catchFruits = 'catchfruits';
  const fruitzzle = 'fruitzzle';
  const [gameSelected, setGameSelected] = useState(null);

  const setGame = (game) => {
    setGameSelected(game);
  }

  const handleCloseGame = () => {
    setGameSelected(null);
  }

  return (
    <div className='games'>
      {gameSelected && <div>
        <FontAwesomeIcon className="gameCloseIcon" icon={faClose} onClick={handleCloseGame} />
      </div>
      }
      {!gameSelected && <div className='head'>
        {/* <button onClick={() => setGame(memoryFruits)} className={gameSelected === memoryFruits ? 'gameSelected' : ''}>{t('memoryfruits')}</button>
        <button onClick={() => setGame(fruitzzle)} className={gameSelected === fruitzzle ? 'gameSelected' : ''}>{t('fruitzzle')}</button> */}
        <img className='gameImg' onClick={() => setGame(memoryFruits)} src={memoryfruitsImg} alt="memoryfruits" />
        <img className='gameImg' onClick={() => setGame(fruitzzle)} src={fruitzzleImg} alt="fruitzzle" />

        {/*<button onClick={() => setGame(catchFruits)} className={ gameSelected === catchFruits ? 'gameSelected' : ''}>{t('catchfruits')}</button>*/}
      </div>
      }
      {gameSelected === memoryFruits &&
        <MemoryFruits />
      }

      {gameSelected === catchFruits &&
        <CatchFruits />
      }

      {gameSelected === fruitzzle &&
        <Fruitzzle />
      }
    </div>
  );
};

export default Games;
