import React, { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAdminClasses, getAdminNews, getAdminUsers } from '../../utils/APIUtils.js';

import './Admin.css';
import AdminUsers from './users/AdminUsers.js';
import AdminClasses from './classes/AdminClasses.js';
import AdminNews from './news/AdminNews.js';
import moment from 'moment';
import AdminSummary from './summary/AdminSummary.js';

function Admin({ user, message, setMessage, loading, setLoading, logoutMain }) {

  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const [usersList, setUsersList] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [newsList, setNewsList] = useState([]);


  const caseInsensitiveSort = (rowA, rowB, columnId) => {
    const a = rowA.values[columnId]?.toString().toLowerCase() || '';
    const b = rowB.values[columnId]?.toString().toLowerCase() || '';
    return a.localeCompare(b);
  };

  function DefaultColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // undefined to remove filter
        }}
        placeholder={`${t('table_filter_find')} (${count} ${t('table_filter_records')})...`}
      />
    );
  }

  const dateSort = (rowA, rowB, columnId) => {

    const dateA = new Date(rowA.values[columnId]);
    const dateB = new Date(rowB.values[columnId]);

    if (!dateA && !dateB) return 0; 
    if (!dateA) return 1; 
    if (!dateB) return -1; 

    return dateA - dateB; 
  };

  const filterByDate = (rows, id, filterValue) => {
    // Verify filter has a value
    if (!filterValue) return rows;

    return rows.filter(row => {
      // Convert date to text DD/MM/YYYY
      const rowDateText = moment(row.values.creationDate).format('DD/MM/YYYY');
      return rowDateText.includes(filterValue); // Compare if has a value in filter
    });
  };

  const fetchUsersData = async () => {
    setLoading(true);
    const theData = await getAdminUsers(user.token);

    if (theData.ok) {

      setUsersList(theData.data);

    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }

  const fetchClassesData = async () => {
    setLoading(true);
    const theData = await getAdminClasses(user.token);

    if (theData.ok) {

      setClassesList(theData.data);

    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }

  const fetchNewsData = async () => {
    setLoading(true);
    const theData = await getAdminNews(user.token);

    if (theData.ok) {

      setNewsList(theData.data);

    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }


  useEffect(() => {
    console.log('fetchdata')
    fetchUsersData();
    fetchClassesData();
    fetchNewsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='divAdmin'>

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList className={'divAdminOptions'}>
          <Tab>{t('summary')}</Tab>
          <Tab>{t('users')}</Tab>
          <Tab>{t('classes')}</Tab>
          <Tab>{t('news')}</Tab>
        </TabList>

        <TabPanel>
          <AdminSummary usersList={usersList} classesList={classesList} />
        </TabPanel>
        <TabPanel>
          <AdminUsers usersList={usersList}
            caseInsensitiveSort={caseInsensitiveSort}
            DefaultColumnFilter={DefaultColumnFilter}
            filterByDate={filterByDate}
            dateSort={dateSort} />
        </TabPanel>
        <TabPanel>
          <AdminClasses usersList={usersList} classesList={classesList}
            caseInsensitiveSort={caseInsensitiveSort}
            DefaultColumnFilter={DefaultColumnFilter}
            filterByDate={filterByDate}
            dateSort={dateSort} />
        </TabPanel>
        <TabPanel>
          <AdminNews
            user={user}
            message={message}
            setMessage={setMessage}
            loading={loading}
            setLoading={setLoading}
            logoutMain={logoutMain}
            newsList={newsList}
            setNewsList={setNewsList}
          />
        </TabPanel>
      </Tabs>
    </div>

  );
};

export default Admin;
