import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { setNewsUserAsReaded } from '../utils/APIUtils.js';

import './News.css';


function News({ user, message, setMessage, loading, setLoading, logoutMain }) {
  const { t } = useTranslation();


  const setNewsAsReaded = async () => {

    if(user && user.news.filter(n => !n.readed).length > 0) {
      setLoading(true);
  
      const theData = await setNewsUserAsReaded(user.token, user);
  
      if (theData && theData.ok) {
        //nothing to do
      } else {
        if (theData && theData.error && theData.error === 'invalid-token') {
          logoutMain();
        } else {
          setMessage({ msg: t(theData.error), type: 'ERR' });
        }
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    setNewsAsReaded();
    // eslint-disable-next-line
}, []);

  return (
    <div className='divNews'>

      {user && user.news && user.news.sort((n1, n2) => new Date(n2.creationDate) - new Date(n1.creationDate)).map((n) => (
        <div className={`divNew ${!n.readed ? 'divNewIsNew' : ''}`} key={n._id}>
          <h1>{n['title' + user.language.toUpperCase()]}</h1>
          <p>{n['description' + user.language.toUpperCase()]}</p>
          {!n.readed && <div className='divNewIcon'>{t('new')}</div>}
        </div>
      ))
      }

    </div>
  );
};

export default News;
