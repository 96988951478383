import React, { useState } from 'react';
import './Selector.css';

function Selector({ options = [], selectedOption = '', onOptionChange }) {
  const [isOpen, setIsOpen] = useState(false);

  
  const getTextLabelFromSelectedOption = () => {
    if(options) {
      let theOpt = options.filter((op) => op.value === selectedOption);
      if(theOpt && theOpt.length > 0) {
        return theOpt[0].label;
      }
    }
    return '';
  }

  const handleChangeOption = (newOption) => {
    onOptionChange(newOption);
    setIsOpen(false);
  };


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='divSelector'>
      <div className="divDropdown">
        <div className="divDropdown-header" onClick={toggleDropdown}>
          {getTextLabelFromSelectedOption()}
          <span className={`arrow ${isOpen ? "open" : ""}`}>▼</span>
        </div>
        {isOpen && (
          <ul className="divDropdown-menu">
            {options.map(option => (
              <li key={option.value} onClick={() => handleChangeOption(option.value, options)}>
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Selector;
