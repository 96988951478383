import avatar1 from '../assets/avatars/avatar1.png';
import avatar2 from '../assets/avatars/avatar2.png';
import avatar3 from '../assets/avatars/avatar3.png';
import avatar4 from '../assets/avatars/avatar4.png';
import avatar5 from '../assets/avatars/avatar5.png';
import avatar6 from '../assets/avatars/avatar6.png';
import avatar7 from '../assets/avatars/avatar7.png';
import avatar8 from '../assets/avatars/avatar8.png';
import avatar9 from '../assets/avatars/avatar9.png';
import avatar10 from '../assets/avatars/avatar10.png';
import avatar11 from '../assets/avatars/avatar11.png';
import avatar12 from '../assets/avatars/avatar12.png';
import avatar13 from '../assets/avatars/avatar13.png';
import avatar14 from '../assets/avatars/avatar14.png';

import especial1 from '../assets/avatars/especial1.png';
import especial2 from '../assets/avatars/especial2.png';
import especial3 from '../assets/avatars/especial3.png';
import especial4 from '../assets/avatars/especial4.png';
import especial5 from '../assets/avatars/especial5.png';

import avatar_default from '../assets/avatars/avatar_default.png';

const avatars = [
    { img: avatar_default, title: 'avatar_default' },
    { img: avatar1, title: 'avatar1' },
    { img: avatar2, title: 'avatar2' },
    { img: avatar3, title: 'avatar3' },
    { img: avatar4, title: 'avatar4' },
    { img: avatar5, title: 'avatar5' },
    { img: avatar6, title: 'avatar6' },
    { img: avatar7, title: 'avatar7' },
    { img: avatar8, title: 'avatar8' },
    { img: avatar9, title: 'avatar9' },
    { img: avatar10, title: 'avatar10' },
    { img: avatar11, title: 'avatar11' },
    { img: avatar12, title: 'avatar12' },
    { img: avatar13, title: 'avatar13' },
    { img: avatar14, title: 'avatar14' },
    
]

const allAvatars = [...avatars, 
    { img: especial1, title: 'especial1' }, 
    { img: especial2, title: 'especial2' },
    { img: especial3, title: 'especial3' },
    { img: especial4, title: 'especial4' },
    { img: especial5, title: 'especial5' },
];



export const getAvatarImage = (avatar) => {
    for(let av of allAvatars) {
        if(avatar === av.title) {
            return av.img;
        }
    }
};

export const getAvatar = (avatar) => {
    for(let av of allAvatars) {
        if(avatar === av.title) {
            return av;
        }
    }
}

export const getAllAvatars = (avatarsUserList = []) => {
    let theList = [...avatars];
    for(let av of allAvatars) {
        if(avatarsUserList.includes(av.title)) {
            theList.push(av);
        }
    }
    return theList;
}