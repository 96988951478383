import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import './Login.css';
import fruitityIcon from '../assets/fruitity-icon.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import { getAllFruitImage } from '../utils/FruitImgUtil.js';

import { auth, googleProvider } from '../firebase/firebase';
import { signInWithPopup } from 'firebase/auth';
import Selector from '../components/Selector.js';
import { getLanguages } from '../language/i18n.js';

function Login({ loading, setLoading, setMessage, setClassesData }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorLogin, setErrorLogin] = useState(null);
    const [fruitsPositions, setFruitsPositions] = useState([]);
    const [language, setLanguage] = useState('');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { login, loginGoogle } = useAuth();

    const { msg } = useParams();

    const onClickLang = (lang) => {

        i18n.changeLanguage(lang);
        localStorage.setItem('lang', JSON.stringify(lang));
        setLanguage(lang);
    }

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            let userGoogle = auth.currentUser;

            loginGoogle(userGoogle, language);
        } catch (error) {
            console.error("Error signing in with Google:", error);
        }
    };

    const handleLogin = async () => {
        setLoading(true);
        const correctLogin = await login({ email: email, password: password });
        if (correctLogin.ok) {
            setClassesData([]);
            let lang = correctLogin.data.language ? correctLogin.data.language : 'es';
            i18n.changeLanguage(lang);
            localStorage.setItem('lang', JSON.stringify(lang));
            navigate('/classes');
        } else {
            setErrorLogin(t(correctLogin.error));
        }
        setLoading(false);
    };

    const clickEye = () => {
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        const fruits = getAllFruitImage();
        let fruitsPos = [];
        let width = window.innerWidth - 100;
        let height = window.innerHeight - 100;
        for (let fr of fruits) {
            let top = Math.floor(Math.random() * (height - 0 + 1) + 0);
            let left = Math.floor(Math.random() * (width - 0 + 1) + 0);

            fruitsPos.push({ id: fr._id, img: fr.img, top: top + 'px', left: left + 'px' });
        }
        setFruitsPositions(fruitsPos);

        //Show message if message is returned from server
        if (msg) {
            setMessage({ msg: t(msg), type: 'ERR' });
        }

        let localStorageLang = localStorage.getItem('lang');

        const changeLanguage = async (language) => {
            await i18n.changeLanguage(language);
            localStorage.setItem('lang', JSON.stringify(language));
        }
        if (localStorageLang) {
            localStorageLang = JSON.parse(localStorageLang);
            changeLanguage(localStorageLang);
            onClickLang(localStorageLang);
            setLanguage(localStorageLang);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const optionsLanguage = getLanguages();


    return (
        <div className='loginDivOutside'>
            <div className='loginDivBackground'>
                <div className='loginDivBackgroundOutside'>
                    {fruitsPositions.map((fr) => (
                        <img alt="frpos" width={'100px'} height={'100px'} src={fr.img} key={fr.id} style={{ top: fr.top, left: fr.left }} />
                    ))}
                </div>
            </div>
            <div className='loginDivInside'>
                <div className='loginDivImg'>
                    <img alt="fruitityicon" className="loginImg" src={fruitityIcon} />
                    <h1 className='menuTitle'>Fruitity</h1>
                </div>
                <div className='loginDivForm'>
                    <h1 className='loginTitle'>{t('login')}</h1>
                    <div className="loginForm">
                        {/* <div class="loginField">
                            <input type="text" id="name" name="name" placeholder="..." />
                            <label for="name">Name</label>
                        </div> */}
                        <div className="loginInput">
                            <input type="email" id="email" name="email" value={email} placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email">{t('email')}</label>
                        </div>
                        <div className="loginInput">
                            <label htmlFor="password">{t('password')}</label>
                            <div className='loginDivPassword'>
                                <input type={showPassword ? 'text' : 'password'} id="password" name="password" value={password} placeholder={t('password')} onChange={(e) => setPassword(e.target.value)} />
                                <FontAwesomeIcon className="loginEyeIcon" icon={showPassword ? faEye : faEyeSlash} onClick={clickEye} />
                            </div>
                        </div>
                        <div className='loginInput'>
                            <label htmlFor="">{t('select_language')}:</label>
                            <Selector
                            options={optionsLanguage} 
                            selectedOption={language} 
                            onOptionChange={onClickLang}  />
                        </div>
                        <button onClick={handleLogin}>{t('login')}</button>
                        <button className='btnLoginGoogle' onClick={signInWithGoogle}>{t('login-with')} <FontAwesomeIcon className='iconLoginGoogle' icon={faGoogle} /></button>


                        <Link to="/signup">
                            <p className='loginLink'>{t('notAccountGoToSignup')}</p>
                        </Link>
                        <Link to="/loginpin">
                            <p className='loginLink'>{t('go-to-login-pin')}</p>
                        </Link>
                    </div>

                </div>
                {errorLogin &&
                    <p className="loginError">{errorLogin}</p>}
            </div>
        </div>
    );
};

export default Login;
