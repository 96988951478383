import React, { useState, useEffect } from 'react';
import './Modal.css';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

function Modal({ message, listFields, msgOk, msgCancel, iconOk, iconCancel, agreeFunction, cancelFunction }) {
    const { t } = useTranslation();

    const [inputValues, setInputValues] = useState({});
    const [errorModal, setErrorModal] = useState(null);

    useEffect(() => {
        let newInputValues = {};
        if (listFields) {
            for (const lf of listFields) {
                newInputValues[lf.name] = lf.value;
            }
        }
        setInputValues(newInputValues);
    }, [listFields]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleOkClick = () => {
        let hasError = false;
        if (listFields) {
            for (const lf of listFields) {
                if (lf.mandatory && (!inputValues.hasOwnProperty(lf.name) || !inputValues[lf.name])) {
                    hasError = true;
                    break;
                }
            }
            if (hasError) {
                setErrorModal(t('modal-error-fill-fields'));
            }
        }
        if (!hasError) {
            agreeFunction(inputValues);
        }
    };

    return (
        <div className='divModalOutside'>
            <div className="divModalInside">
                <button className='btnModalClose' onClick={cancelFunction}>
                    <FontAwesomeIcon className='iconFruitity iconFruititySmall' icon={faClose} />

                </button>
                <h3>{message}</h3>
                <div className='divModalInputs'>
                    {listFields && listFields.length > 0 && (
                        <>
                            {listFields.map((lf) => {

                                const { name, type, placeholder, mandatory } = lf;
                                return (
                                    <div key={name}>
                                        {/* <label>{(placeholder ? placeholder : name) + (mandatory ? '*' : '')}</label> */}
                                        <input
                                            key={name}
                                            type={type}
                                            name={name}
                                            placeholder={(placeholder ? placeholder : name) + (mandatory ? '*' : '')}
                                            id={name}
                                            value={inputValues[name] || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                )
                            })}
                        </>
                    )}
                    {errorModal && <p className='modalError'>{errorModal}</p>}
                </div>
                <div className='divModalButtons'>
                    <button onClick={handleOkClick}>
                        {iconOk &&
                            <>
                                <FontAwesomeIcon className='' icon={iconOk} />
                                {'  '}
                            </>
                        }
                        {msgOk}
                    </button>
                    <button onClick={cancelFunction}>
                        {iconCancel &&
                            <>
                                <FontAwesomeIcon className='' icon={iconCancel} />
                                {'  '}
                            </>}
                        {msgCancel}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;